@import "../../Styles/mixins"
@import "../../Styles/icons"

.socialLinks
  @include h-flex

  .icon
    @include square(1.75em)
    margin: 0 0.5em
    cursor: pointer

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    &.fb
      @include setBackground('../Assets/Icons/New User/facebook-f.svg', contain)

    &.tw
      @include setBackground("../Assets/Icons/New User/twitter.svg", contain)

    &.in
      @include setBackground("../Assets/Icons/New User/instagram.svg", contain)
