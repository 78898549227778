@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.footer
  width: 100%
  background: $greyBackground
  @include padding-large(4em)
  padding-bottom: 0

  .topContent
    @include h-flex
    padding-bottom: 2em
    align-items: flex-start

    .subscribe
      flex: 5

      p
        @extend %mediumText
        line-height: 1.8
        color: white

      #mc_embed_signup_scroll
        @include h-flex
        height: 2.5em
        margin-right: 2em
        position: relative
        border-radius: 0.25em
        margin-top: 2em

        .mc-field-group
          flex: 1

          input
            background: transparent
            width: 100%
            height: 100%
            padding: 0.5em
            border-radius: 0.25em 0 0 0.25em
            border: 1px solid white
            border-right: none
            color: $extraLightText

        .defaultButton
          height: 100%
          border-radius: 0 0.25em 0.25em 0


    .company, .resources
      flex: 2.5

      h1
        color: white
        margin-bottom: 1em
        @extend %smallBlockHeading

      .links
        @include v-flex
        align-items: flex-start

        a
          color: $extraLightText
          text-decoration: none
          @extend %smallMediumText
          line-height: 2.287

    .appLinks
      flex: 2


  .bottomContent
    @include h-flex
    padding: 1em 0
    border-top: 1px solid rgba(255, 255, 255, 0.5)

    .fullLogo
      width: 8em
      transform: translate(0, 5%)

    & > p
      flex: 1
      text-align: center
      color: white
      @extend %xLightText


