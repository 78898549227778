@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"
@import "../../Styles/icons"

.inputWrapper
  width: 100%
  position: relative
  margin: 1.5em 0 1.75em

  .loading
    background: url($inputLoader) right center /contain no-repeat !important
    background-size: 15px 15px !important

  .saved
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='35px'><text x='0' y='30' fill='lightgray' font-size='14'>saved</text></svg>") right center no-repeat !important

  .loadingEditor
    background: url($inputLoader) 99% 1em /contain no-repeat !important
    background-size: 15px 15px !important

  .savedEditor
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='35px'><text x='0' y='30' fill='lightgray' font-size='14'>saved</text></svg>") 99% 0 no-repeat !important

  &.disabled
    pointer-events: none

    input, textarea
      border-bottom: none !important

  u
    text-decoration: underline

  .placeholder
    color: $darkTextColor
    pointer-events: none
    position: absolute
    padding: 0.5em 0
    transition: all 300ms ease
    top: 0
    width: 100%
    @include h-flex
    justify-content: flex-start
    font-size: $s05

    span
      flex: 1
      @include h-flex
      justify-content: flex-end

    &.active
      font-size: $s1
      padding: 0
      transform: translate(0, -100%)
      color: $lightText

  .select
    color: #C7402D
    font-size: 12px
    top: -1.5em

  .error
    height: 1em
    margin-top: 0.5em
    @include v-flex
    align-items: flex-start
    font-size: 0.75em
    color: $darkAccentText
    transition: height 150ms ease, padding 150ms ease
    padding: 0.5em 0 1em

    &:empty
      height: 0
      padding: 0

  input, textarea
    width: 100%
    overflow: hidden

    &:not([type="submit"])
      background: transparent
      color: $mediumDarkTextColor
      padding: 0.5em 0
      border: none
      border-bottom: 1px solid $lightBorderColor
      resize: none

    &[type="submit"]
      padding: 1em 2.5em
      font-size: 1.125em

    &:disabled
      background: none

    &:not(:placeholder-shown) + .placeholder, &:focus + .placeholder
      font-size: $s1
      padding: 0
      transform: translate(0, -100%)
      color: $lightText

  .visibilityToggle
    @include square(1em)
    @include setBackground($eye, contain)
    display: block
    position: absolute
    top: 0
    right: 0
    transform: translate(-0.5em, 0.5em) scale(1.5)
    cursor: pointer
    filter: saturate(0)
    -webkit-filter: saturate(0)

    &.active
      filter: none
      -webkit-filter: none

  &.datePicker
    .react-date-picker__wrapper
      padding: 0.5em 0
      border: none
      border-bottom: 1px solid $lightBorderColor
      transform: translate(0, 0.35em)

    .react-date-picker
      width: 100%

      span
        padding: 0.25em 0

      input
        background: unset
        color: $mediumDarkTextColor
        padding: 0
        border: none
        resize: none


  &.radio
    margin-top: 1em !important
    @include h-flex
    flex-wrap: wrap

    .error
      width: 100%

    .placeholder
      position: relative
      color: $lightText
      margin-right: 1em

    .radioContainer
      flex: 1
      @include h-flex

      & > div
        border: none !important
        padding: 1em 0 !important
        margin-bottom: 0 !important
        flex: unset !important

        & > div
          flex-direction: row-reverse !important
          align-items: center
          justify-content: flex-start !important

          & > div:first-child
            margin-left: 0.5em

          & > div:nth-child(2)
            $size: 0.75em
            margin-left: 0.5em
            @include v-flex
            padding: 0 !important
            border-width: 1px !important
            @include square($size)

            & > div
              @include square($size)
              background-color: $darkAccentText !important

        p
          filter: saturate(0) brightness(0.7) contrast(2.5)
          -webkit-filter: saturate(0) brightness(0.7) contrast(2.5)

  .autocomplete-dropdown-container
    position: absolute
    z-index: 1
    width: 100%
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25)
    padding: 0.5em
    background-color: white

    .suggestion-item
      background-color: transparent !important
      margin: 0.5em 0

    &:empty
      padding: 0

  .quill
    .ql-toolbar
      height: 50px

    .ql-container
      height: 146px

  &.editor
    .placeholder
      position: relative
      margin-bottom: 0.5em

.timeOverlay
  @include overlay
  position: fixed
  z-index: 9999999999
  background: rgba(0, 0, 0, 0.5)
  backdrop-filter: blur(10px)
  @include v-flex

  .react-timekeeper__time-dropdown
    display: none
