@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

$width: 25em

.login, .register, .forgotPassword, .resetPassword
  height: calc(100vh - 5em)
  width: 100%
  @include v-flex
  justify-content: center
  align-items: center

  &.embed
    height: auto

    .sectionDesign
      background-color: unset
      padding: 0

      & > h1, & > h2
        display: none

  .sectionDesign
    background-color: $lightBackground
    min-width: 35em
    width: 40vw
    padding: 3em 0
    border-radius: 0.75em

    & > h1
      font-size: 1.75em
      font-weight: bold
      position: relative
      height: 2em
      @include h-flex
      justify-content: center

      .fullLogo
        transform: translate(4%, -3%) scale(0.975)

    & > h2
      font-size: 1.125em
      text-align: center
      line-height: 1.5
      margin: 1.5em 0 1.25em

      span
        color: $mediumDarkTextColor

    & > p
      font-size: 0.75em
      color: $mediumDarkTextColor
      text-align: center

      a
        color: $darkAccentText
        text-decoration: none
        border-bottom: 1px solid $darkAccentText
        font-size: 1.125em
        cursor: pointer

      .forgotLink
        display: block
        font-size: $s05
        margin: 1em 0

        a
          color: $darkTextColor
          border-bottom-color: $darkTextColor


    .content
      @include v-flex

      .passwordLogin, .registerForm
        margin-top: 0.75em
        width: $width

        & > p
          font-size: 0.75em
          color: $mediumDarkTextColor
          text-align: center

        .inputWrapper, .defaultButton
          width: 100%
          margin: 1.25em 0
          font-size: 0.825em

      .googleLogin, .facebookLogin
        width: $width
        font-size: 1em
        @include v-flex
        margin-bottom: 0.75em

        & > span
          width: 100%

        button
          width: 100%
          @include h-flex
          justify-content: center

