@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.courseCard, .masterclassCard
  width: 100%
  height: 100%
  padding-bottom: 2em !important
  position: relative
  cursor: pointer
  @include v-flex

  .courseImage
    width: 100%
    padding: 56.25% 0 0

  .courseDetails
    width: 100%
    background: $greyBackground
    color: white
    padding: 1.25em
    flex: 1
    @include v-flex
    justify-content: flex-start
    align-items: flex-start

    .courseName
      @extend %cardTitle
      margin-bottom: 0.25em

    .teacherName
      @extend %lightText
      margin-bottom: 1em

      span
        color: $accentText

    .rating
      height: 1em
      @include h-flex
      justify-content: flex-start
      margin-bottom: 1em

    .row
      @include h-flex
      width: 100%
      margin: 0
      flex: 1
      align-items: flex-start !important

      .chapter
        flex:1 1


      .fee
        margin-left: 0.5em

        &.withDiscount
          .original
            display: inline-block
            position: relative
            font-weight: normal
            opacity: 0.75
            letter-spacing: 0.025em

            &:after
              content: ''
              @include v-center
              left: 0
              width: 100%
              height: 2px
              background: white

      .duration
        flex: 1
        justify-content: flex-start
        @extend %lightText
        align-items: flex-start
        margin-bottom: 0.25em

        span
          color: $accentText

        .icon
          display: inline-block
          @include square(0.75em)
          margin-right: 0.25em
          @include setBackground('../Assets/Icons/New User/Home/card_clock_icon.svg', contain)


.teacherCard
  width: 100%
  flex: 1
  margin-bottom: 2em !important
  position: relative
  cursor: pointer
  @include v-flex

  .teacherImage
    padding-top: 100%
    width: 100%

  .teacherDetails
    background: linear-gradient(to bottom, transparent 15%, $greyBackground 45%)
    color: white
    padding: 4.5em 1.25em 1.25em
    width: 100%
    flex: 1
    position: absolute
    bottom: 0


    .teacherName
      @extend %cardTitle
      margin-bottom: 1em

    .instruments, .place
      margin: 1em 0

    .rating
      font-size: 1em

      h1.value
        color: white
        height: 100%


.featureCard
  @include h-flex
  align-items: flex-start
  justify-content: center
  width: 22.5em

  .icon
    @include square(3.5em)
    margin-right: 1.35em

    img
      max-height: 100%
      max-width: 100%

  .info
    & > h1
      @extend %smallHeading
      margin-top: 0

    & > p
      font-size: 1em
      width: 12em
      line-height: 1.5em


.testimonialCard
  background: white
  padding: 1.5em 3.4em
  border-radius: 1em
  margin: 1em
  box-shadow: 0 0 12px 0 rgba(105, 105, 105, 0.13)
  height: 100%

  .courseInfo
    h1
      @extend %smallHeading
      color: $mediumDarkTextColor
      width: 20em

    p
      color: $lightText
      @extend %smallSubHeading

      span
        @extend %mediumText


  & > p
    margin-top: 1.5em
    @extend %mediumText
    line-height: 1.625
    color: $mediumDarkTextColor
    width: 30em
    max-width: 100%

    .quoteMarks
      @include square(2.125em)
      @include setBackground('../Assets/Icons/New User/Home/testimonial_quotes.svg', contain)
      display: inline-block
      transform: translate(0, 10%)
      margin-right: 1em

  .student
    margin-top: 0.5em
    @include h-flex
    justify-content: flex-start

    img
      @include square(4em)
      margin-right: 1em
      border-radius: 50%

    p
      @extend %mediumText
      color: $accentText


.classCard
  $color: #417505
  $bg: rgb(242, 255, 228)
  margin-top: 2em !important
  background-color: white
  border: 1px solid $blueBorder
  border-radius: 0.15em
  padding: 1em 1em 0.5em
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.5)
  position: relative
  pointer-events: auto

  &.deactivated
    box-shadow: none
    background: #e7e7e7
    pointer-events: none
    border-color: #333

    h1
      color: #777

    .row
      .status
        p
          border-color: #777
          color: #777

  .reschedulePopup
    position: absolute
    right: 0
    top: 0
    padding: 0.5em 1.5em
    background: $bg
    transform: translate(0, calc(-100% - 0.75em))
    border: 1px solid $color
    filter: drop-shadow(0 0 0.25em rgba(87, 95, 45, 0.35))
    -webkit-filter: drop-shadow(0 0 0.25em rgba(87, 95, 45, 0.35))
    opacity: 0
    pointer-events: none
    transition: opacity 250ms ease
    border-radius: 0.25em
    cursor: pointer
    user-select: none

    &:hover
      .reschedulePopup
        opacity: 1
        pointer-events: auto

    &:before
      content: ''
      border-bottom-right-radius: 0.25em
      @include h-center
      top: 100%
      @include square(0.75em)
      transform: translate(-50%, -50%) rotateZ(45deg)
      background: $bg
      border-right: 1px solid $color
      border-bottom: 1px solid $color
      z-index: 1

    &:after
      content: ''
      @include h-center
      top: 101%
      height: 0.75em
      width: 100%

  &:hover
    .reschedulePopup
      opacity: 1
      pointer-events: auto

  h1
    font-size: $l55
    font-weight: 600
    margin-bottom: 1em
    color: $darkAccentText

  .rating
    margin-top: 0.5em

  .defaultButton
    font-size: $s05

  .row
    @include h-flex
    align-items: flex-end
    font-weight: 600
    font-size: $l55
    line-height: 1.5

    .info
      flex: 1

    .status
      p
        font-size: $s1
        padding: 0.25em 0.5em
        border: 1px solid $color
        text-transform: uppercase
        color: $color
        margin-bottom: 0.5em

        &:empty
          display: none

.courseCard
  &.type-group
    overflow: hidden

    .groupCourseTag
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

      span
        @include square(1.25em)
        margin-right: 0.5em
        @include setBackground('../Assets/Icons/Active user/group.svg', contain)


.masterclassCard
  position: relative
  overflow: hidden

  &.type-master-class
    &:after
      font-size: 0.5rem
      content: "Masterclass"
      position: absolute
      padding: 0.5rem 0
      background: red
      color: white
      transform: translate(-50%, -50%) rotateZ(-45deg)
      left: 1.9rem
      top: 1.9rem
      width: 14.313708498984761em
      @include h-flex
      border-top: 1px solid yellow
      border-bottom: 1px solid yellow
      box-shadow: 0 0 0.5em rgba(255, 0, 0, 1)
      letter-spacing: 0.05em

    &.isSeries
      &:after
        content: "Masterclass Series"

  &.type-workshop
    &:after
      content: "Workshop"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

  &.type-demo
    &:after
      content: "Demo"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

  &.type-qna
    &:after
      content: "Q&A"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

  &.type-btm
    &:after
      content: "Behind the Music"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

  &.type-concert
    &:after
      content: "Concert"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

    &.isSeries
      &:after
        content: "Workshop Series"

  &.type-gig
    &:after
      content: "Live Gig"
      position: absolute
      background: rgba(255, 255, 255, 0.5)
      backdrop-filter: blur(25px)
      color: black
      top: 1.5em
      right: 0
      padding: 0.5em 1em
      @include h-flex
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 1)
      letter-spacing: 0.05em

    &.isSeries
      &:after
        content: "Live Gig Series"
