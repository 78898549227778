@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.PremiumCourse
  @include v-flex

  .sectionHeader
    width: 100%
    text-align: center
    padding: 2.125em 25% 1em
    color: $darkTextColor
    font-size: $l3


  .sectionIntro
    font-size: $l5
    font-weight: 400
    padding: 0 25%
    text-align: center
    margin-bottom: 2em

  .viewMore
    font-size: 1.125em
    width: 100%
    @include h-flex
    margin: 1.5em 0 4em

    button
      padding: 0.75em 1.5em