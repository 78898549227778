@import "./general"
@import "./Accordion"
@import "./Notifications"
@import "./Popup"
@import "./NewVersionPrompt"

*
  margin: 0
  padding: 0
  box-sizing: border-box
  outline: none
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-decoration: none

body
  background: white

.App
  //topNav
  padding-top: 5em
.icon:hover
  background: grey 
button
  cursor: pointer

input
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

a
  color: inherit


::-webkit-scrollbar
  width: 10px

::-webkit-scrollbar-track
  background: #c7c7c7

::-webkit-scrollbar-thumb
  background: #999
  border-radius: 2px

::-webkit-scrollbar-thumb:hover
  background: #999
  cursor: pointer

@media screen and (max-device-width: 480px)
  .App
    width: 100vw
    overflow: hidden
    font-size: 2em

    button, a
      display: none
