@import "../../Styles/mixins"
@import "../../Styles/colors"

.topNav
  height: 5em
  background: black
  @include padding-small()
  @include h-flex
  position: fixed
  top: 0
  z-index: 100

  & > a:first-of-type
    margin-left: 1em

  & > a > .defaultButton
    margin-left: 1em

  .browserSupport
    position: absolute
    top: 100%
    background: $darkAccentText
    width: 100%
    color: white
    @include h-flex
    padding: 1em 25%
    text-align: center
    line-height: 1.5

    .close
      @include v-center
      right: 0
      @include square(1.25em)
      @include setBackground('../Assets/Icons/Notifications/Close.svg', contain)
      margin-right: 2.5em
      cursor: pointer

    a
      text-decoration: underline

  .fullLogo
    height: 75%
    padding: 0.5em 0.5em 0.5em 1em
    margin-right: 5.5em

  .links
    height: 100%
    @include h-flex
    justify-content: flex-start

    .defaultButton
      margin-right: 2em

  .searchBox
    min-width: 25em
    margin-right: 1em

    input
      width: 100%
      font-family: "Gotham Book", sans-serif

  .signIn
    border: none
    background: none
    color: white
    font-size: 1em
    margin-left: 3em

  .notifications
    margin-left: 2em

  .userContextMenu
    margin-left: 3em

    & > .icon
      @include square(2em)
      @include v-flex
      @include setBackground('../Assets/Icons/Loged in not enrolled/Header/account.svg', contain)
      background-position-y: 0
      cursor: pointer
      border-radius: 0.5em


.userContextMenuListOverlay
  @include overlay
  position: fixed
  z-index: 1001

  & > *
    user-select: none

  .userContextMenuList
    background-color: white
    position: fixed
    top: 4em
    right: 4.125%
    border-radius: 0.25em
    width: 12.5em
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
    padding: 0.5em

    .userContentMenuItem
      padding: 0.75em 0
      font-size: 0.825em
      @include h-flex
      justify-content: flex-start
      cursor: pointer

      &:hover
        background: $grey87

      .icon
        @include square(1.5em)
        margin: 0 1em
        padding: 0

        &.logout
          @include setBackground('../Assets/Icons/Loged in not enrolled/power.svg', contain)

        &.profile
          @include setBackground('../Assets/Icons/Loged in not enrolled/Profile.svg', contain)

        &.courses
          @include setBackground('../Assets/Icons/Loged in not enrolled/mycourses.svg', contain)
