@import "../../../Styles/mixins"
@import "../../../Styles/icons"
@import "../../../Styles/colors"
@import "../../../Styles/typography"

.searchBox
  position: relative
  width: 25vw

  .icon
    @include v-center
    right: 1em
    @include square(1.5em)
    filter: brightness(0.5)
    -webkit-filter: brightness(0.5)
    @include setBackground($search, contain)

  &:focus-within
    .icon
      display: none


.searchInput
  width: 100%

  .courseSuggestion, .teacherSuggestion, .instrumentSuggestion
    padding: 0 1em
    transition: background 300ms ease
    cursor: pointer
    background: transparent
    @include h-flex
    justify-content: flex-start


    .searchIcon
      display: block
      @include square(1.5em)
      @include setBackground('https://unsplash.it/500', contain)
      margin-right: 1em

      &.courseIcon
        @include setBackground('../Assets/Icons/New User/Home/classes_icon.svg', contain)

      &.teacherIcon
        @include setBackground('../Assets/Icons/New User/Search/teacher_icon.svg', contain)

      &.instrumentIcon
        @include setBackground('../Assets/Icons/New User/Search/instrument_icon.svg', contain)

    p
      flex: 1

  .noOptionsMessage
    width: 100%
    @include h-flex
    font-size: 1em
    color: $lightText
    padding: 0.5em 0
