@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.allBroadcastClasses
  @include v-flex

  .sectionHeader
    width: 100%
    text-align: center
    padding: 2.125em 25% 1em
    color: $darkTextColor
    font-size: $l3
    margin-bottom: 0

  & > h1
    margin: 2em 0 1em

  .sectionIntro
    font-size: $l5
    font-weight: 400
    @include padding-large(1em)
    text-align: justify
    margin-bottom: 2em

  .viewMore
    font-size: 1.125em
    width: 100%
    @include h-flex
    margin: 1.5em 0 4em

    button
      padding: 0.75em 1.5em

  .footer
    margin-top: 4em
