@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.loadingAnimation
  flex: 1
  height: 100%
  width: 100%
  @include h-flex

  #Fill-1
    transform-origin: 50% 50%
    transform: scale(0.85)

  #Group-23
    animation: spin 5s linear infinite
    transform-origin: 50% 50%


@keyframes spin
  from
    transform: rotateZ(0deg)
  to
    transform: rotateZ(360deg)