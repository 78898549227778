@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"
@import "../../Styles/icons"

.errorBoundary
  @include center
  @include v-flex
  width: 66vw
  padding: 2em
  border-radius: 10px
  background-color: $gray98Background
  box-shadow: 0 0 4px 0 rgba(191, 197, 210, 0.44)

  h1
    color: $darkAccentText
    font-size: $l1
    font-weight: bold
    text-align: center
    padding-top: 0.5em
    margin-bottom: 0.5em

  h2
    font-size: $l5
    font-weight: 400
    text-align: center
    max-width: 45vw
    margin-bottom: 0.5em

  p
    text-align: center
    margin-bottom: 0.5em
    font-size: $s1
    color: $lightText
    @include singleLineText
    display: none

  .errorImage
    @include v-flex

    .icon
      @include square(20vw)
      display: block

      &.illustration
        @include setBackground('../Assets/Icons/Teacher_s Dashboard/illustration.svg', contain)

  .errorBoundaryButtons
    display: flex
    justify-content: center

    button
      width: 8.875em
      height: 2.563em
      box-shadow: 3px 3px 10px 2px rgba(199, 64, 45, 0.3)
      padding: 0 1.5em
      margin: 0 1em
