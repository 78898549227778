@use "sass:math"

@mixin v-center()
  position: absolute
  top: 50%
  transform: translate(0, -50%)

@mixin h-center()
  position: absolute
  left: 50%
  transform: translate(-50%, 0)

@mixin center()
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

@mixin v-flex()
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around

@mixin h-flex()
  display: flex
  align-items: center
  justify-content: space-around

@mixin overlay()
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

@mixin singleLineText()
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  width: 100%

@mixin horizontalSplit($parts, $spacing)
  position: relative
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  
  & > *
    width: math.div(100% - ($spacing * ($parts - 1) * 2), $parts)
    margin: 0 $spacing

    &:nth-child(#{$parts}n+1)
      margin-left: 0

    &:nth-child(#{$parts}n)
      margin-right: 0

@mixin setBackground($image, $fit)
  background: url($image) center center /$fit no-repeat

@mixin square($size)
  height: $size
  width: $size
  min-height: $size
  min-width: $size

@mixin padding-small($verticalPadding: 0)
  width: 100%
  padding: $verticalPadding 4.125%

@mixin padding-medium($verticalPadding: 0)
  width: 100%
  padding: $verticalPadding 10%

@mixin padding-large($verticalPadding: 0)
  width: 100%
  padding: $verticalPadding 13.5%

//noinspection CssOverwrittenProperties
@mixin fullHeight()
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  overflow: auto

@mixin capsule($color: black)
  border: 1px solid $color
  border-radius: 100vh
  padding: 0 0.25em
