@import "./mixins"
@import "./colors"

.popup-content
  width: unset !important
  background: $lightGreyBackground !important
  color: white !important
  box-shadow: none !important
  font-size: 0.825em
  border-radius: 0.2em
  padding: 0.5em 1em !important
  border: none !important
  z-index: 1000 !important

  & > div
    &:first-child
      background: $lightGreyBackground !important
      box-shadow: none !important