@import "./mixins"
@import "./colors"
@import "./typography"

.newVersionPrompt
  position: fixed
  z-index: 100000000000
  top: 12.5vh
  right: 2.5vw
  width: 30em
  padding: 1em 2em
  background: white
  border-radius: 0.5em
  box-shadow: 0 0 1em rgba(125, 125, 125, 1)
  @include h-flex
  justify-content: center

  h2
    color: $darkAccentText
    font-weight: 500
    font-size: $l55
    line-height: 1.5
    letter-spacing: 0.0125em
    margin-right: 2em

  button
    padding: 0.66em 1.5em
    color: white
    border: none
    font-weight: bold
    font-size: 0.9125em
    border-radius: 0.25em
    cursor: pointer
    letter-spacing: 0.05em
    background: #C7402D linear-gradient(196.67deg, #C7402D 0%, #EC9770 100%)
    box-shadow: 0.25em 0.25em 0.625em rgba(199, 64, 45, 0.3)
    transition: transform 150ms ease
    @include h-flex
    display: inline-flex
    position: relative
