$font-base: 'Gotham A', 'Gotham B', 'Gotham', 'HomepageBaukasten', sans-serif !important
$font-heading: 'Gotham A', 'Gotham B', 'Gotham', 'HomepageBaukasten', sans-serif !important

$l1: 3.052em
$l2: 2.441em
$l3: 1.953em
$l4: 1.563em
$l5: 1.25em
$l55: 1.125em

$s7: 1.09em
$s6: 1em
$s05: 0.889em
$s1: 0.8em
$s2: 0.64em
$s3: 0.512em

*
  font-family: $font-base

span
  font-family: inherit

%mediumText
  font-size: 1em
  font-weight: 500

%largeMediumText
  font-size: 1.25em
  font-weight: 500
  line-height: 1.35

%extraLargeMediumText
  font-size: 1.5em
  font-weight: 500
  line-height: 1.35

%smallMediumText
  font-size: 0.875em
  font-weight: 500

%xSmallText
  font-size: 0.75em
  font-weight: normal

%smallText
  font-size: 0.825em
  font-weight: normal

%lightText
  font-size: 1em
  font-weight: 300

%xLightText
  font-weight: 300
  font-size: 0.825em
  letter-spacing: 0.025em

%spacedText
  font-size: 1em
  line-height: 1.375

%mediumSpacedText
  font-size: 1em
  line-height: 1.5

%largeSpacedText
  font-size: 1em
  line-height: 1.625

%mediumSectionTitle
  font-size: 1.875em
  font-weight: 500

%sectionTitle
  font-size: 1.875em
  font-weight: 700

%xsTitle
  font-size: 1em
  font-weight: 700

%heading
  font-size: 2em

%largeHeading
  font-size: 2.5em

%smallHeading
  font-weight: 700
  font-size: 1.25em
  margin: 0.25em 0
  line-height: 1.45

%smallRegularHeading
  font-size: 1.25em
  line-height: 1.45

%smallBlockHeading
  font-weight: 500
  font-size: 1.25em
  text-transform: uppercase

%lightBlockHeading
  font-size: 1em
  text-transform: uppercase
  font-weight: 300

%subHeading
  font-size: 1.125em
  line-height: 1.27
  font-weight: bold

%smallSubHeading
  font-size: 1.0625em
  line-height: 1.1764

%lightSubHeading
  font-size: 1.25em
  font-weight: bold
  color: #2D2D2D

%cardTitle
  font-size: 1.125em
  font-weight: 500
  line-height: 1.38

%biggerText
  font-size: 1.125em
  line-height: 1.38
  font-weight: normal

%xsLabel
  font-size: 0.75em
  font-weight: 500

body
  font-size: $s05

@media screen and (max-width: 1366px)
  body
    font-size: 0.825em
