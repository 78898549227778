@import "./mixins"
@import "./typography"
@import "./colors"

.accordion
  .accordion__item
    border-bottom: 1px solid $lightBorderColor
    padding: 2em

    &:first-child
      border-top: 1px solid $lightBorderColor

    .accordion__title
      cursor: pointer

      h1
        @extend %smallRegularHeading
        color: $darkAccentText
        margin-bottom: 0.5em

      p
        display: none
        @include singleLineText
        width: 75%
        @extend %mediumSpacedText

      &.closed
        p
          display: block

  .accordion__body
    *
      @extend %mediumSpacedText

    li
      margin-bottom: 0.5em

      &:last-child
        margin-bottom: 0

