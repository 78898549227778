@import "./mixins"
@import "./colors"
@import "./typography"

.Toastify__toast-container
  width: 27.5vw !important
  z-index: 9999999999 !important

  .Toastify__toast
    padding: 0 !important
    border-radius: 0.25em
    min-height: 0

    .toast
      @include h-flex
      background: white
      padding: 0.5em 0

      &:before
        content: ''
        position: absolute
        left: 0
        height: 100%
        top: 0
        width: 5em

      &.error
        border-left: 0.25em solid #d90f0f

      &.success
        border-left: 0.25em solid #15a458

      &.warning
        border-left: 0.25em solid #ec9870

      &.notify
        border-left: 0.25em solid #3c91e6

      span
        margin: 0 1.5em

        &.error
          @include square(2.5em)
          @include setBackground('../Assets/Icons/Notifications/Warning.svg', contain)

        &.success
          @include square(2.5em)
          @include setBackground('../Assets/Icons/Notifications/Success.svg', contain)

        &.warning
          @include square(2.5em)
          @include setBackground('../Assets/Icons/Notifications/Error.svg', contain)

        &.notify
          @include square(2.5em)
          @include setBackground('../Assets/Icons/Notifications/Notification.svg', contain)

      .info
        width: 100%

        .header
          font-size: $l55
          margin-top: 0.5em
          margin-bottom: -0.75em
          font-weight: 500
          letter-spacing: 0.025em

          &.error
            color: #d90f0f

          &.success
            color: #15a458

          &.warning
            color: #ec9870

          &.notify
            color: #3c91e6

          &:empty
            display: none

        .text
          width: 100%
          align-items: center
          padding: 1em 1em 0.5625em 0
          line-height: 1.35
          color: $darkTextColor
          opacity: 0.75


