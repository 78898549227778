@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.upcomingClass
  position: fixed
  right: 2.5%
  top: 12.5%
  height: 14em
  width: 25em
  background-color: white
  box-shadow: 0 0 2em rgba(112, 112, 112, 1)
  border-radius: 0.5em
  padding: 1em
  overflow: hidden
  transition: height 300ms ease, transform 300ms ease
  transform-origin: 100% 0
  z-index: 1000

  .top
    @include h-flex
    width: 100%
    align-items: center
    margin-bottom: 0.5em
    height: 2.5em

    h1
      font-size: $l5
      color: black
      flex: 1

    a
      opacity: 0
      transition: opacity 300ms ease
      pointer-events: none

    .minimize
      position: absolute
      right: 1.25em
      @include square(1em)
      cursor: pointer
      transition: opacity 300ms ease

      &:before
        content: ''
        @include v-center
        width: 80%
        left: 0
        height: 0.075em
        background: black


  .content
    height: 9.5em
    @include v-flex
    align-items: flex-start

    P
      color: $mediumDarkTextColor
      margin-bottom: 1em
      line-height: 1.35

      span
        font-weight: 500
        color: $darkAccentText


  &.minimized
    cursor: pointer
    height: 4.5em
    transform: scale(0.75)

    .top
      a
        opacity: 1
        pointer-events: auto
        transition-delay: 300ms

      .minimize
        opacity: 0
        pointer-events: none