@import "../../Styles/mixins"
@import "../../Styles/typography"
@import "../../Styles/icons"

.defaultButton, input[type="submit"]
  padding: 0.66em 1em
  color: white
  border: none
  border-radius: 0.25em
  cursor: pointer
  letter-spacing: 0.05em
  background: #C7402D linear-gradient(196.67deg, #C7402D 0%, #EC9770 100%)
  box-shadow: 0.25em 0.25em 0.625em rgba(199, 64, 45, 0.3)
  transition: transform 150ms ease
  @include h-flex
  display: inline-flex
  position: relative

  &:active
    transform: scale(0.95)

  span
    position: absolute
    display: block
    left: 0.75em
    @include square(1.75em)

    &.heart
      @include setBackground($heart, contain)
      filter: invert(1)
      -webkit-filter: invert(1)

    &.tickIcon
      @include setBackground('../Assets/Icons/New User/Meet our teacher/tick.svg', contain)

    &.plusIcon
      @include setBackground('../Assets/Icons/New User/Meet our teacher/plus.svg', contain)
      transform: scale(0.85)

    &.whiteheart
      @include square(1.3em)
      @include setBackground($whiteheart, contain)

    &.image
      @include square(1.3em)
      @include setBackground('../Assets/Icons/Teacher_s Dashboard/My Courses/pdf.svg', contain)

    &.video
      @include square(1.3em)
      @include setBackground('../Assets/Icons/Teacher_s Dashboard/My Courses/doc_containing_video.svg', contain)

    &.audio
      @include square(1.3em)
      @include setBackground('../Assets/Icons/New User/Course Page/study_material_icon.svg', contain)

    &.url
      @include square(1.3em)
      @include setBackground('../Assets/Icons/Teacher_s Dashboard/My Courses/attachment.svg', contain)
      filter: invert(1)
      -webkit-filter: invert(1)

    &.document
      @include square(1.3em)
      @include setBackground('../Assets/Icons/Teacher_s Dashboard/My Courses/pdf.svg', contain)
      filter: invert(1)
      -webkit-filter: invert(1)


  &.withIcon
    padding-left: 3em !important

  &.primary
    padding: 0.7225em 1.5625em

  &.secondary
    background: none
    border: 1px solid #C7402D
    color: #C7402D
    box-shadow: none

  &.border
    background: none
    border: 1px solid #fff
    border-radius: 0.25em
    box-shadow: none
    padding: calc(0.66em - 2px) 1.5em

  &.disabled
    filter: saturate(0)
    -webkit-filter: saturate(0)
    opacity: 0.5
    cursor: auto


  .loadingAnimation
    div
      div
        margin-top: 0
        margin-bottom: 0

.socialLoginButton
  padding: 1em 2em
  font-weight: bold
  color: white
  @include h-flex
  border: none
  width: 100%
  border-radius: 0.15em

  .icon
    @include square(1.5em)
    background-color: red

  .label
    flex: 1

  &.fb
    background-color: #3b5998

    .icon
      @include setBackground($facebookFull, contain)

  &.google
    background-color: #ea4335

    .icon
      @include setBackground($google, contain)
      filter: brightness(0) invert(1)
      -webkit-filter: brightness(0) invert(1)

  &.linkedin
    background-color: #0e76a8

    .icon
      @include setBackground($linkedin, contain)
  
  &.twitter
    background-color: #00acee

    .icon
      @include setBackground($twitter, contain)

  &.whatsapp
    background-color: #075e54

    .icon
      @include setBackground($whatsapp, contain)

