$veryLightBackground: #fafafa
$lightBackground: #f9f9f9
$greyBackground: #212121
$lightGreyBackground: #aeaeae
$transparentBlackBackground: rgba(0, 0, 0, 0.66)
$gray97: #f7f7f7
$gray98Background: #fafafa
$grey81: #d0cfcf
$grey87: #e0e0e0
$whiteSmokeBackground: #F3F3F3
$accentText: #EC9871
$darkAccentText: #C7402D
$extraLightText: #CAC9C9
$dimGrayText: #6D6D6D
$lightText: #9B9B9B
$mediumDarkTextColor: #4A4A4A
$lightGreyColor: #A7A4A7
$darkTextColor: #2D2D2D
$lightIsGrayTextColor: #8E8E8E
$lightBorderColor: #cdcbcd
$greyBorder: #dcdfe7
$lightGreyBorder: #e8ebf1
$green: #4ede92
$darkGreen: #65af15
$white: #ffffff
$dimGrayColor: #686868
$brightGrayColor: #585C64
$chineseSilver: #cccccc
$blueGreyText: #8c98a1
$coral: #ec9870
$offWhite: #ffefe7
$blueBorder: #3c91e6