@import "../../Styles/mixins"
@import "../../Styles/typography"
@import "../../Styles/colors"
@import "../../Styles/icons"

.rating
  @include h-flex
  justify-content: flex-start
  font-size: $l5

  span
    margin: 0 0.05em

  h1
    color: $accentText
    @extend %xSmallText
    margin-left: 0.5em
    transform: translate(0, 0.125em)

  .ratingsNumber
    @extend %xSmallText
    color: $accentText
    border-bottom: 1px solid $accentText
    padding-bottom: 0.25em
    transform: translate(0, 0.25em)
    margin-left: 1em

  .star, .halfStar
    @include square(1em)
    margin-right: 0.125em

    &.hidden
      @include setBackground('../Assets/Icons/New User/rating_grey.svg', contain)
      pointer-events: none

  .star
    @include setBackground('../Assets/Icons/New User/rating_yellow.svg', contain)

  .halfStar
    @include setBackground('../Assets/Icons/New User/rating_yellow.svg', contain)
    background-position-x: 0.5em
    transform: translate(-50%, 0)

  &.inputMode
    .star, .halfStar
      &.hidden
        opacity: 1
        pointer-events: auto
