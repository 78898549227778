@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"

.Landing
  .banner
    @include padding-large()
    background-color: $veryLightBackground
    @include h-flex
    height: 12em

    .stat
      @include h-flex
      align-items: flex-start
      justify-content: center
      width: 20em
      transform: translate(10%, 0)

      .icon
        $size: 3.5em
        height: $size
        width: $size
        display: block
        background-color: grey
        margin-right: 1.5em

      &:nth-child(1)
        .icon
          @include setBackground('../Assets/Icons/New User/Home/classes_icon.svg', contain)

      &:nth-child(2)
        .icon
          @include setBackground('../Assets/Icons/New User/Home/teachers_icon.svg', contain)

      &:nth-child(3)
        .icon
          @include setBackground('../Assets/Icons/New User/Home/avg_per_lesson.svg', contain)

      .details
        color: #2D2D2D

        .heading
          @extend %lightSubHeading
          margin-bottom: 0.25em

        .description
          @extend %spacedText
          width: 12em


  .featuredClasses
    width: 100%
    background-color: white

    .loadingAnimation
      min-height: 10em !important

    & > p
      width: 100%
      text-align: center
      padding: 4em


  .howItWorks
    background: $veryLightBackground
    padding: 4em 0
    @include v-flex

    .sectionTitle
      @extend %sectionTitle

    .intro
      margin: 1em 0
      text-align: center
      width: 40em
      line-height: 1.5em

    .process
      @include h-flex
      justify-content: center
      width: 100%
      margin: 2em 0 0

      .featureCard
        margin: 0 2.35em


  .becomeInstructor
    background: $veryLightBackground
    padding: 5.5em 0
    @include v-flex

    .sectionTitle
      @extend %sectionTitle

    p
      margin: 0.75em 0 1.5em
      line-height: 1.5
      width: 32em
      text-align: center

  .sectionHeader
    width: 100%
    text-align: center
    @extend %mediumSectionTitle
    color: $darkTextColor

  .sectionHeaderContainer
    padding: 2.125rem 25%
    display: flex
    flex-direction: column 
    align-items: center
    justify-content: center

  .sectionSubHeader
    font-size:0.9rem
    color: #615d5d
    font-weight: 300
    padding-top:0.25rem
    text-align: center
  
  
  .viewMore
    font-size: 1.125em
    width: 100%
    @include h-flex
    margin: 1.5em 0 4em

    button
      padding: 0.75em 1.5em


@keyframes slide-bottom
  0%
    transform: translate(0, 100%)
  100%
    transform: translate(0, 0)

@keyframes fade
  0%
    opacity: 0
  75%
    opacity: 1
  95%
    opacity: 1
  100%
    opacity: 0

