@import "../../Styles/mixins"
@import "../../Styles/typography"
@import "../../Styles/colors"


.courseList, .teacherList, .masterclassList
  width: 100%
  @include padding-large()
  @include horizontalSplit(3, 1.25%)

.allMasterclasses
  & > h1
    margin-bottom: 1em

  .comingSoon
    width: 100%
    font-size: $l3
    color: $darkAccentText
    text-align: center
    margin: 2em 0
