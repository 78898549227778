@import "../../Styles/mixins"
@import "../../Styles/colors"
@import "../../Styles/typography"


.confirmation
  @include overlay
  @include v-flex
  position: fixed
  background: rgba(0, 0, 0, 0.25)
  z-index: 1000000000

  .modal
    background: white
    @include v-flex
    max-width: 33vw
    padding: 4.5vh 3.5vw
    border-radius: 0.4em
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25)

  .message
    flex: 1
    margin-bottom: 2em
    text-align: center

  .actions
    .defaultButton
      padding: 0.5em 0
      width: 7.5em
      margin: 0 1em
