@import "../../Styles/mixins"
@import "../../Styles/typography"
@import "../../Styles/icons"
@import "../../Styles/colors"

.notifications
  .icon
    @include square(2em)
    padding: 0.5em
    @include v-flex
    @include setBackground('../Assets/Icons/Loged in not enrolled/Header/notification.svg', contain)
    cursor: pointer
    position: relative

    .unread
      pointer-events: none
      background-color: $darkAccentText
      color: white
      @include square(1.5em)
      @include v-flex
      font-size: 0.75em
      font-weight: bold
      border-radius: 50%
      position: absolute
      top: 0
      right: 0
      transform: translate(33%, -33%)

.notificationsOverlay
  @include overlay
  position: fixed
  z-index: 101

  & > *
    user-select: none

  .notificationsList
    background-color: white
    position: fixed
    top: 4em
    right: calc(5em + 4.125%)
    border-radius: 0.25em
    width: 20em
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)

    .notificationsListHeader
      display: flex
      justify-content: space-between
      border-bottom: 1px solid rgba(0, 0, 0, 0.2)
      align-items: center

      p
        text-decoration: underline
        color: $darkAccentText
        cursor: pointer

        &.markRead
          font-size: $s1

      & > h1, p
        margin: 0 0.5em
        font-size: 1.0625em
        font-weight: normal
        padding: 0.75em 0.5em

    .notifications
      height: 25.5em
      max-height: 50vh
      padding: 0 0.5em 1em

    .ps__rail-y
      left: unset !important
      right: 0 !important

    .notificationElement
      padding: 2.5em 0
      font-size: 0.75em
      @include h-flex
      position: relative
      border-bottom: 1px solid rgba(0, 0, 0, 0.15)
      cursor: pointer

      &:last-child
        border-bottom: none

      &:hover
        background-color: $veryLightBackground

      .picture
        @include square(2.5em)
        border-radius: 50%
        margin: 0 1.5em

      .time
        position: absolute
        bottom: 0.5em
        right: 0
        font-size: 0.875em
        opacity: 0.5

      .message
        padding-right: 1em
        flex: 1

      .new
        background-color: $green
        color: white
        padding: 0.125em 0.5em
        top: 0.5em
        right: 0
        position: absolute


